.main-no-padding {
    padding-left: 0px;
}

.calibration-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
}

    .calibration-form .logo {
        width: 100px;
        margin-bottom: 20px;
    }

    .calibration-form h2 {
        margin-bottom: 10px;
        font-size: 1.5em;
        color: #333;
    }

    .calibration-form h4 {
        margin-bottom: 20px;
        font-size: 1.2em;
        color: #333;
    }

    .calibration-form .answer-option {
        display: block;
        width: 100%;
        padding: 20px;
        margin: 10px 0;
        background-color: #fff;
        color: #333;
        border: 2px solid #e0e0e0;
        border-radius: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s;
        text-align: left;
    }
      
    .calibration-form .answer-option:hover {
        background-color: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .calibration-form .progress-bar {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #e0e0e0;
        border-radius: 4px;
        overflow: hidden;
        margin: 0px;
    }

    .calibration-form .progress-bar-inner {
        height: 10px;
        background-color: #007bff;
        width: 0;
        transition: width 0.3s;
    }

    .calibration-form .completion-message {
        text-align: center;
    }

        .calibration-form .completion-message h3 {
            margin-bottom: 20px;
            color: #28a745;
        }

        .calibration-form .completion-message ul {
            list-style-type: none;
            padding: 0;
        }

        .calibration-form .completion-message li {
            margin-bottom: 10px;
        }


    .loading-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .boat {
            position: relative;
            width: 50px;
            height: 50px;
            animation: float 2s infinite ease-in-out;
        }

        .hull {
            width: 50px;
            height: 20px;
            background-color: #004d40; /* Dark oceanic color */
            border-radius: 0 0 10px 10px;
        }

        .sail {
            position: absolute;
            top: -30px;
            left: 15px;
            width: 20px;
            height: 30px;
            background-color: #ffffff;
            border-left: 2px solid #004d40;
        }

        .waves {
            display: flex;
            justify-content: space-between;
            width: 100px;
            margin-top: 10px;
        }

        .wave {
            width: 20px;
            height: 10px;
            background-color: #00796b;
            border-radius: 50%;
            animation: wave 1s infinite ease-in-out;
        }

    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    @keyframes wave {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px);
        }
    }

@media screen and (max-width: 600px) {
    .calibration-form {
        padding: 10px;
    }

        .calibration-form .logo {
            width: 80px;
        }

        .calibration-form h2 {
            font-size: 1.2em;
        }

        .calibration-form h4 {
            font-size: 1em;
        }

        .calibration-form .card-button {
            padding: 10px;
        }
}