.exceeded-limit-notice {
    font-size: 14px;
    color: #acacac;
    text-align: center;
}


.card-button {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
  }
  
  .card-button:hover {
    transform: translateY(-5px);
  }
  
  .card-button p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }

  .card-style-1 {
    border: 1px solid var(--premium-box-stroke, hsla(200, 81%, 71%, .5));
    background: var(--premium_box_bg, #f2faff);
  }

  .card-style-2 {
    border: 1px solid var(--vip-box-stroke-250, hsla(33, 81%, 71%, .5));
    background: var(--vip_box_bg_2, #fffcf2);
  }

  .card-style-3 {
    border: 1px solid var(--alert-box-stroke, hsla(0, 81%, 71%, .5));
    background: var(--alert_box_bg, #fff2f2);
  }