.profile-detail-container {
    padding: 5px 15px;
}

.profile-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .user-name {
        margin: 10px 0px;
    }

    .logout-button {
        background: none;
        border: 2px solid #000;
        height: fit-content;
        padding: 5px 15px;
        border-radius: 20px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
    }

    .logout-button:hover {
        background-color: #ff0000;
        color: #fff;
    }