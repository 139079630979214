.karmic-record-list {
    margin: 10px 0px;
}

.empty-message {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-top: 20px;
}

@media screen and (max-width: 600px) {
    .karmic-record-summary .MuiTypography-subtitle1 {
        font-size: 0.875rem;
    }

    .MuiTypography-body2 {
        font-size: 0.75rem;
    }

    .MuiTypography-caption {
        font-size: 0.625rem;
    }

    .no-karmic-records {
        font-size: 0.75rem; 
    }

    .MuiAccordionDetails-root .karmic-record-meta {
        flex-direction: column;
        align-items: flex-end;
    }
}