.confrontation-screen {
  margin: 65px 15px;
}

  .toggle-journal-button {
    position: fixed;
    display: flex;
    gap: 3px;
    align-items: center;
    background: none;
    padding: 5px 15px;
    font-size: 15px;
    border-radius: 30px;
    top: 6px;
    right: 15px;
    z-index: 9999;
  }

    .toggle-journal-button svg {
      font-size: 15px;
    }    

  .confrontation-content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

    .choices-container {
      width: 100%;
    }

      .choice-section.previous-choice {
        background-color: #f9f9f9;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
        transition: background-color 0.3s ease; 
        font-size: 14px;
      }

      .choice-section.previous-choice:hover {
        background-color: #f1f1f1;
      }

        .choice-content {
          padding: 10px;
          border-left: 3px solid #ddd;
          margin-left: 10px;
        }

        .choice-previous-karmic-record {
          font-style: italic;
          color: #555; 
          margin-bottom: 5px;
        }

        .choice-narrative {
          color: #333;
        }

        .choice-decision {
          display: flex;
          align-items: flex-start;
          color: #444;
          padding: 5px 0;
        }
      
      .current-choice, .streaming-container {
        padding: 15px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
      }

        .current-choice-narrative {
          font-size: 15px;
          margin-bottom: 20px;
        }

        .options-container {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
          flex-wrap: wrap;
        }

          .options-container button {
            font-size: 15px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            background-color: #f1f1f1;
            cursor: pointer;
            border-radius: 5px;
            text-align: left;
            min-width: 150px;
          }

          .options-container button.selected {
            background-color: #4CAF50;
            color: #ffffff;
            border: 2px solid #4CAF50;
          }
        
          .options-container button:disabled {
            background-color: #f0f0f0;
            color: #ccc;
            cursor: not-allowed;
          }

        .current-choice-confirm-button{
          padding: 10px 20px;
          border: none;
          background-color: #4CAF50;
          color: #ffffff;
          cursor: pointer;
          border-radius: 5px;
        }
        
        .current-choice-confirm-button:disabled {
          background-color: #e0e0e0;
          cursor: not-allowed;
        }

        .ending-section {
          padding: 15px;
          background-color: #f9f9f9;
          border: 1px solid #ddd;
          border-radius: 5px;
        }    

          .ending-label {
            font-weight: 600;
            color: #333;
            margin-bottom: 10px;
          }

  .figure-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
  }
  
    .figure-card {
      position: relative;
    }
    
    .figure-image {
      max-width: 100%;
      height: auto;
      border-radius: 5px 5px 0px 0px;
    }

    .figure-details {
      position: absolute;
      bottom: 40px;
      width: 100%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      color: white;
      padding: 10px;
      border-radius: 5px;
      text-align: left;
      box-sizing: border-box;
    }

      .details-name {
        font-weight: 600;
      }

      .details-description {
        font-size: 14px;
      }
  
    .toggle-button {
      font-size: 14px;
      height: 40px;
      padding: 10px 20px;
      border: none;
      background-color: #4CAF50;
      color: #ffffff;
      cursor: pointer;
      border-radius: 0px 0px 5px 5px;
    }

  .confrontation-progress-bar {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: calc(100% - 240px);
    height: 14px;
    background-color: #e0e0e0;
  }
  
    .confrontation-progress {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: #76c7c0; 
      color: #EAE8D8;
      font-size: var(--font-size-small);
      padding: 0px 14px;
      transition: width 0.3s ease-in-out;
    }

  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .popup {
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 450px;
      padding: 15px 20px;
      position: relative;
  }
  
  .popup-header {
      display: flex;    
      justify-content: flex-end;
  }
      .close-icon {
          cursor: pointer;
      }
  
  .popup-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
  }
  
  .popup-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
      .popup-content textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          resize: none;
          box-sizing: border-box;
      }
  
      .popup-content button {
          padding: 10px 20px;
          border: none;
          background-color: #76c7c0;
          color: white;
          cursor: pointer;
          border-radius: 4px;
      }
  
      .popup-content button:hover {
          background-color: #5aa9a1;
      }

@media screen and (max-width: 600px) {
  .confrontation-screen {
    margin: 5px 15px;
  }

    .popup {
      width: 100%;
      margin: 15px;
    }

    .confrontation-content {
      flex-direction: column;
    }

    .figure-container.ongoing {
      display: none;
    }

      .figure-card {
        min-width: unset;
      }

      .options-container button {
        font-size: var(--font-size-small);
      }

  .confrontation-progress-bar {
    width: 100%;
  }
}