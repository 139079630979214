body {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-main);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-accent);
  color: var(--color-text);
  margin-bottom: var(--spacing-medium);
}

a {
  color: var(--color-link);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-link-hover);
}

button {
  color: var(--color-text);
  background-color: var(--color-button-background);
  border: 2px solid var(--color-border);
  border-radius: 20px;
  padding: 10px 20px;
  font-family: var(--font-main);
  font-size: var(--font-size-medium);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--color-button-hover);
}

input, textarea {
  background-color: var(--color-input-background);
  border: 1.5px solid var(--color-border);
  border-radius: 10px;
  padding: 10px;
  font-family: var(--font-main);
  font-size: var(--font-size-medium);
}

input:focus, textarea:focus {
  outline: none;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 240px;
}

/* Responsive Design */
@media (max-width: 768px) {
  body {
    font-size: var(--font-size-small);
  }

  .container {
    padding: 0 10px;
  }

  main {
    padding: 48px 0px 75px;
  }
}