.logo-container {
  display: flex;
  align-items: center;
  padding: 16px;
  user-select: none;
}

.logo-image {
  width: 35px;
  height: auto;
  margin-right: 8px;
}

.logo-text {
  color: inherit;
  font-family: 'Helvetica Neue', sans-serif;
}

.secondary-top-nav {
  padding-left: 230px;
}

@media (max-width: 600px) {
  .logo-container {
    padding: 8px;
  }

  .logo-image {
    width: 28px; 
    margin-right: 4px;
  }

  .logo-text {
    font-size: 1rem; 
  }

  .secondary-top-nav {
    padding-left: 0px;
  }
}