:root {
    /* Color Palette */
    --color-background: #f5f3f3;
    --color-text: #3D3251;
    --color-border: #DDDDDD;
    --color-link: #4A4A4A;
    --color-link-hover: #817970;
    --color-input-background: rgba(255, 255, 255, 0.6);
    --color-button-background: rgba(255, 255, 255, 0.8);
    --color-secondary-button-background: #E9C46A;
    --color-button-hover: #94D2BD;
    --color-header-background: #f1efed;
    --color-footer-bg: #4C696D;
    --color-footer-text: #817970;

    /* Fonts */
    --font-main: 'Noto Sans SC', sans-serif;
    --font-accent: 'Noto Serif SC', serif;

    /* Font Sizes */
    --font-size-small: 0.875rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.25rem;
    --font-size-xlarge: 1.5rem;

    /* Spacing */
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;
}

