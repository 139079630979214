.figure-detail-container {
    margin: 65px 15px;
    font-family: 'Helvetica Neue', sans-serif;
}

.figure-detail-header {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 30px;
}

.figure-detail-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    aspect-ratio: 9 / 16;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out;
}

.figure-detail-image:hover {
    transform: scale(1.05);
}

.figure-detail-info {
    flex: 1;
}

.figure-detail-name {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
}

.figure-detail-description {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 10px;
}

.figure-detail-confrontations {
    margin-top: 30px;
}

.figure-detail-confrontation-item {
    margin-bottom: 15px;
}

.figure-detail-confrontation-link,
.figure-detail-continue-btn {
    font-size: 1.1rem;
    color: #0066cc;
    text-decoration: none;
    display: block;
    padding: 10px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease-in-out;
    text-align: center;
}

.figure-detail-confrontation-link:hover,
.figure-detail-continue-btn:hover {
    background-color: #f0f8ff;
}

.figure-detail-confrontation-btn {
    font-size: 1.2rem;
    padding: 12px 20px;
    border: none;
    background-color: #0077b6;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
}

.figure-detail-confrontation-btn:hover {
    background-color: #005f8a;
}

@media (max-width: 600px) {
    .figure-detail-container {
        margin: 5px 15px;
    }

    .figure-detail-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .figure-detail-name {
        font-size: 2rem;
    }

    .figure-detail-description {
        font-size: 1.1rem;
    }

    .figure-detail-image {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .figure-detail-name {
        font-size: 1.6rem;
        margin-top: 0px;
    }

    .figure-detail-description {
        font-size: 1rem;
    }

    .figure-detail-confrontation-btn,
    .figure-detail-continue-btn {
        font-size: 1rem;
    }
}
