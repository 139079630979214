.realm-detail-container {
    margin: 65px 15px;
    font-family: 'Helvetica Neue', sans-serif;
}

.realm-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 20px;
}

.realm-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.realm-image:hover {
    transform: scale(1.05);
}

.realm-name {
    font-size: 2.6rem;
    font-weight: 500;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;
}

.realm-description, 
.realm-cultural-context {
    font-size: 1.15rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
}

.realm-figures {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
}

.figure-link a {
    font-size: 1rem;
    color: #0077b6;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in-out;
    display: block;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.figure-link a:hover {
    color: #005f8a;
    border-bottom: 2px solid #0077b6;
    background-color: #f0f8ff;
}

@media (max-width: 600px) {
    .realm-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .realm-name {
        font-size: 2.2rem;
        margin-top: 20px;
    }

    .realm-image {
        max-width: 100%;
        width: 100%;
    }

    .realm-description, 
    .realm-cultural-context {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .realm-detail-container {
        margin: 5px 15px;
    }

    .realm-name {
        font-size: 1.5rem;
        margin-top: 0px;
    }

    .realm-description, 
    .realm-cultural-context {
        font-size: 0.95rem;
    }

    .realm-figures {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }

    .figure-link a {
        font-size: 0.9rem;
    }
}
