.understanding-list {
    margin: 10px 0px;
}

.understanding-note {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    transition: background-color 0.2s ease;
}

.understanding-note:hover {
    background-color: #f1f1f1;
}

.understanding-wrapper {
    display: flex;
    flex-direction: column;
}

.understanding-content {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 8px;
}

.understanding-info {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #888;
    margin-top: 10px;
}

.review-link {
    color: #007BFF;
    font-weight: bold;
    text-decoration: none;
}

.review-link:hover {
    text-decoration: underline;
}

.empty-message {
    font-size: 16px;
    color: #888;
    text-align: center;
}