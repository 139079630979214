.preoccupation-list-container {
  font-family: 'Arial', sans-serif;
}

.preoccupation-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 10px 0px;
}

.preoccupations, .other-preoccupations {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preoccupation-button {
  background-color: #fff;
  color: #555;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.preoccupation-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.preoccupation-button.other {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.expand-button-container {
  margin: 10px 0px;
}

.expand-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.expand-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .preoccupation-button, .expand-button {
      font-size: 12px;
      padding: 6px 12px;
  }
}
