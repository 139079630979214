.archetype-list {
  padding: 0px 15px;
  border-radius: 8px;
}

.archetype-list-heading {
  font-size: 18px;
  margin: 10px 0px;
}

.archetype-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.archetype-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.archetype-item:hover {
  transform: translateY(-5px);
}

.archetype-item a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}