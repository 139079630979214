.confronted-figure-list {
    margin: 10px 0px;
}

.title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.figure-item {
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
}

.figure-item:hover {
    background-color: #f1f1f1;
}

.figure-link {
    font-size: 16px;
    font-weight: 500;
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
}

.figure-link:hover {
    color: #0056b3;
}

.empty-message {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-top: 20px;
}
