.login-container-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 10000;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.logo-container {
}

.login-logo {
  width: 100px; 
}

.login-container {
  background-color: white;
  padding: 20px 40px;
  margin: 0 auto;
}

  .login-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }

  .phone-input, .code-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .code-input {
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .phone-input label {
    margin-right: 10px;
    font-weight: bold;
  }

  input[type="text"] {
    font-size: 14px;
    padding: 15px;
  }

  .phone-input input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .code-input input {
    width: 180px;
    border: none;
  }

  .get-code-button {
    font-size: 14px;
    margin-right: 15px;
    color: #4a90e2;
    background: none;
    border: none;
    padding: 0px;
    cursor: pointer;
    border-radius: 5px;
    width: 93px;
    text-align: right;
  }

  .get-code-button:hover {
    background: none;
    color: #0056b3;
  }

  .get-code-button[disabled] {
    color: #ccc;
  }

  .login-button {
    font-size: 14px;
    width: 100%;
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .login-button:hover {
    background-color: #0056b3;
  }

  .login-button[disabled] {
    background-color: #ccc;
  }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    font-size: 13px;
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .checkbox-container a {
    color: #4a90e2;
    text-decoration: none;
  }

.footer-text {
  font-size: 12px;
  color: #9b9b9b;
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .login-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-top: 50px;
  }

  .login-logo {
    width: 70px;
  }

  .login-container {
    padding: 20px 0px;
  }

}