.favorited-figure-list {
    margin: 10px 0px;
}

.figure-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
}

.figure-item:hover {
    background-color: #f5f5f5;
}

.figure-link {
    font-size: 16px;
    font-weight: 500;
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
}

.figure-link:hover {
    color: #0056b3;
}

.favorite-toggle-btn {
    background-color: #ff4d4f;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.favorite-toggle-btn:hover {
    background-color: #d7383b;
}

.empty-message {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-top: 20px;
}
