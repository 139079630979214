.figure-list {
}

.view-toggle {
    display: flex;
    background-color: var(--color-background);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

    .view-toggle.no-ongoing button {
        width: 100%;
    }

    .view-toggle button {
        width: 50%;
        padding: 14px 20px;
        margin: 0;
        background-color: transparent;
        border-radius: 0px;
        cursor: pointer;
        transition: background-color 0.3s;
        border: none;
        border-bottom: 4px solid transparent;
    }

    .view-toggle button.active {
        font-weight: bold;
        background-color: transparent;
        border-bottom: 4px solid #3D3251;
    }

.figure-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    overflow: hidden;
    position: relative;
    margin: 15px 0px;
}

    .figure-card-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
    }

    .arrow-icon {
        cursor: pointer;
        font-size: 2rem;
    }
        
    .left-arrow {
        position: absolute;
        left: -2rem;
    }
    
    .right-arrow {
        position: absolute;
        right: -2rem;
    }

    .figure-card {
        position: relative;
        width: 32%;
        min-width: 365px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        background-size: cover;
        background-position: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        border-radius: 14px;
        overflow: hidden;
    }

        .favorite-button {
            position: absolute;
            top: 5px;
            right: 5px;
            background: none;
            border: none;
            cursor: pointer;
            color: #e63946; 
            font-size: 1.5em;
            transition: color 0.3s;
        }
        
        .favorite-button:hover {
            color: #d62828; 
        }

        .figure-card-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
            padding: 10px;
        }

            .figure-name {
                font-size: var(--font-size-large);
                font-weight: bold;
                color: #EAE8D8;
                text-decoration: none;
            }

            .figure-card-buttons {
                display: flex;
                justify-content: space-between;
            }

            .figure-card-buttons button {
                border: none;
                cursor: pointer;
                border-radius: 5px;
                padding: 7px 14px;
                transition: background-color 0.3s, color 0.3s;
                background-color: #ffcd3f;
                color: #000000;
            }

            .figure-card-buttons button:hover {
                background-color: #457b9d;
                color: #f1faee;
            }

@media (max-width: 768px) {
    .view-toggle button {
        padding: 7px 14px;
    }

    .figure-carousel {
        margin: 0px;
        height: calc(100vh - 165px);
    }

    .figure-card-container {
        width: 100%;
    }
    
        .figure-card {
            width: 100%;
            border-radius: 0px;
        }

        .left-arrow {
            left: 0;
            z-index: 1;
        }

        .right-arrow {
            right: 0;
            z-index: 1;
        }

        .figure-card-content {
            position: fixed;
            width: 100%;
            box-sizing: border-box;
            bottom: 76px;
        }
}