.individual-consciousness {
  display: flex;
  flex-direction: column;
}

  .evolution-bar {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 240px);
    height: 20px;
    background-color: rgba(250, 250, 250, 0.8);
    margin: 0;
    z-index: 100;
  }

    .evolution {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      background-color: #94D2BD;
      transition: width 0.3s ease-in-out;
    }

    .current-realm, .percentage {
      position: absolute;
      font-size: var(--font-size-small);
      top: 50%;
      transform: translateY(-50%); 
    }

    .current-realm {
      left: 10px;
    }

    .percentage {
      right: 10px;
    }

/* Responsive Design */
@media (max-width: 768px) {
  .evolution-bar {
    bottom: 56px;
    width: 100%;
  }
}