.payment-method-title {
    font-size: 14px;
}

.payment-methods {
    display: flex;
    gap: 25px;
    margin-bottom: 10px;
    justify-content: center;
}

    .payment-methods button {
        border: 2px solid #f0f0f0;
        background-color: transparent;
        cursor: pointer;
        border-radius: 5px;
        padding: 0px 15px;
    }

    .payment-methods button:hover {
        background-color: #f0f0f0;
    }

    .payment-method-button.selected {
        border: 2px solid #76c7c0;
    }

.checkout-button {
    
}

    .checkout-button:disabled, .checkout-button:disabled:hover {
        color: #ccc;
        background-color: #e0e0e0;
        cursor: not-allowed;
    }

.payment-qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

    .payment-price {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: center;
    }

        .payment-price span {
            font-size: 21px;
        }

    .payment-qrcode-container p {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 14px;
    }

    .payment-qrcode-container img {
        width: 14px;
        height: 14px;
    }

.error {
    font-size: 14px;
    text-align: center;
}

@media (max-width: 600px) {
    .payment-method-button {
        width: 100%;
    }
}